export const teamMates
 = [
  {
    name: "Aditya Patel",
    image: process.env.PUBLIC_URL + "/images/Adi.png",
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Amanda Pfeil",
    image: process.env.PUBLIC_URL + '/images/Amanda_2.png',
    title: "Graduate Student",
    bio: "Amanda has a background in Geography and Sustainability, and is a graduate student in the Energy and Earth Resources Program. Her work focuses on urban tree canopy as a Nature-Based Solution for carbon and thermal mitigation.",
    linkedin:"https://www.linkedin.com/in/amandapfeil3",
    email:"akp2593@my.utexas.edu"
  },
  {
    name: "Alexia Leclercq",
    image: process.env.PUBLIC_URL + "/images/alexia.png",
    title: "Staff",
    bio: "Alexia works with UT and various city departments in creating and utilizing localized climate data to advance climate resilience, adaptation, and mitigation. With a background in grassroots environmental justice organizing, federal climate policy, and participatory research Alexia brings experience from working in academia, politics, and frontline communities to foster meaningful collaboration for change. Her notable achievements include pushing for an equitable fossil fuel phase out at the UN, successful campaigns against the petrochemical industry's expansion, contributing to climate and land use laws, protecting the Colorado River, and confounding an environmental justice education non-profit, Start:Empowerment, which has reached thousands of youth across the U.S. Additionally, Alexia has received prestigious awards such as the Grist 50 Fixer, Brower Youth Award, Harvard AOCC Award, WWF Conservation Award and been featured on platforms such as Forbes, The Washington Post, Now This News, and The Guardian. She holds a bachelor degree in environmental science and politics from the Gallatin School of Individualized Study at New York University and a masters degree from the Harvard Graduate School of Education.",
    email:"alexia.leclercq@utexas.edu",
    linkedin:"https://www.linkedin.com/in/alexia-leclercq/"
  },
  {
    name: "Alka Tiwari",
    image: process.env.PUBLIC_URL + '/images/Alka.png',
    title: "Postdoctoral Researcher",
    bio: "I am a postdoc, working as a part of The Extreme weather and Urban Sustainability (TExUS) Lab. I have received my PhD from Purdue University advised by Dr. Keith Cherkauer and mentored by Dr. Frank Marks, Dr. Wen-wen Tung and Dr. Dev Niyogi as my committee members. I was advised by Dr. Shivam Tripathi for my Master's in Civil Engineering, majoring in Hydraulics and Water Resources Engineering at IIT Kanpur.Academically, I have spent about two years teaching as Assitant Professor atthe School of Civil Engineering, KIIT University and being a Project Scientist at Interdisciplinary Centre for Water Research (ICWaR) -IISc Bangalore.",
    email:"alka.tiwari@jsg.utexas.edu"
  },
  {
    name: "Allysa Dallmann",
    image:process.env.PUBLIC_URL +"/images/Allysa.png",
    title: "Staff",
    bio: "Allysa has a background in Meteorology and Climate Dynamics. The core of her position is to work hand and hand with the UT City Climate CoLab to understand what are the needs in city departments and how we can provide information and climate data tools to them for their operations and local decision making here in Austin.",
    linkedin:"https://www.linkedin.com/in/allysa-dallmann/",
    email:"dallmann@utexas.edu"
  },
  {
    name: "Ali Mousavinezhad ",
    image: process.env.PUBLIC_URL + "/images/Ali_Mousavinezhad_1.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Arya Chavoshi",
    image: process.env.PUBLIC_URL + '/images/Arya.png',
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Caleb Adams",
    image: process.env.PUBLIC_URL + '/images/Caleb.png',
    title: "Graduate Student",
    bio: "Caleb is a PhD student in the Department of Earth and Planetary Sciences and a DOE Computational Science Graduate Fellow. Caleb's primary research interests are in using process based numerical modeling to study plant hydraulics and fire behavior and effects.",
    email:"cea2786@my.utexas.edu",
    linkedin:"https://www.linkedin.com/in/caleb-earl-adams"
  },
  {
    name: "Dariya Fallon",
    image: process.env.PUBLIC_URL + "/images/Dariya.jpeg",
    title: "Staff",
    bio: "Dariya is a recent graduate of The University of Texas at Austin’s Master's program in Energy and Earth Resources, complemented by an undergraduate background in economics. Her research focuses on the energy impacts and potential financial benefits of employing sustainable building practices in multi-family residential real estate projects in Austin, Texas.",
    email:"dariyafallon@utexas.edu",
    linkedin:"https://www.linkedin.com/in/dariya-fallon/"
  },
  {
    name: "Dev Niyogi",
    image: process.env.PUBLIC_URL + "/images/Dev-Pic-Aug2024.jpg",
    title: "Principal Investigator (PI)",
    bio: "Professor and William Stamps Farish Chair, Department of Earth and Planetary Sciences, Jackson School of Geosciences, and Department of Civil, Architectural and Environmental Engineering, Cockrell School of Engineering, Affiliate Faculty: Oden Institute for Computational Engineering and Sciences, and UT Center for Space Research, The University of Texas at Austin",
    linkedin:"https://www.linkedin.com/in/texus",
    website: "https://niyogi.dev",
    email:"dev.niyogi@jsg.utexas.edu"
  },
  {
    name: "Harsh Kamath",
    image: process.env.PUBLIC_URL + '/images/Harsh.png',
    title: "Graduate Student",
    bio: "I am a PhD student working on urban meteorology. My primary focus is urban heat, urban dataset generation using AI/ML methods, development of urban parametrizations for offline and weather models. I also have a keen interest in urban boundary layer processes.",
    linkedin:"https://www.linkedin.com/in/harshkamath",
    email:"harsh.kamath@utexas.edu"
  },
  {
    name: "Hassan Dashtian",
    image:process.env.PUBLIC_URL +"/images/Hassan.jpeg",
    title: "Research Assistant Professor",
    bio: ""
  },
  {
    name: "Koorosh Azizi",
    image: process.env.PUBLIC_URL + "/images/Koorosh_2.png",
    title: "PostDoctoral Researcher",
    bio: "Koorosh Azizi is a Postdoctoral Fellow at the Jackson School of Geosciences. His research focuses on the interdisciplinary aspects of water and environmental systems, including the impacts of climate risk on infrastructure and communities, decision-making processes, coupled human-natural systems, policy analysis, sustainability, as well as mitigation and adaptation strategies.",
    email:"koorosh.azizi@austin.utexas.edu",
    website:"https://scholar.google.com/citations?user=MXlzhmcAAAAJ&hl=en",
    linkedin:"https://www.linkedin.com/in/koorosh-azizi-058367b2"
  },
  {
    name: "Manmeet Singh",
    image: process.env.PUBLIC_URL + "/images/manmeet.png",
    title: "PostDoctoral Researcher",
    bio: "Manmeet Singh is a Distinguished Postdoctoral Fellow at the Jackson School of Geosciences, The University of Texas at Austin. He has extensive experience in climate science, having worked as a Scientist at the Indian Institute of Tropical Meteorology (IITM), Ministry of Earth Sciences, Government of India, for over a decade. His research focuses on climate solutions, with an emphasis on land, ocean, and atmospheric interactions using mathematical models such as numerical weather prediction systems. Manmeet has pioneered the use of AI/ML techniques, causal methods, and nonlinear time series analysis to address complex challenges in Earth System Science. Notably, he contributed to the development of the IITM Earth System Model for the IPCC AR6 report and led the coupling of its aerosol module. His recent work has shown significant advancements in high-impact short-range weather predictions using deep learning, and he has developed novel AI-driven algorithms for high-resolution downscaling. Manmeet’s expertise has earned him numerous accolades, including the Fulbright-Kalam Climate Fellowship and the prestigious Distinguished Postdoctoral Fellowship from UT Austin. He remains active in academic outreach, delivering invited talks at venues such as NASA and Microsoft India, and supervising several advanced research projects in climate data science. His PhD research from IIT Bombay focused on the impacts of volcanic eruptions as an analogue for solar geoengineering and their effects on the South Asian Monsoon. He is currently leading cutting-edge projects using AI-enhanced climate models for applications ranging from flood forecasting and urban climate resilience to wildfire risk and financial impact assessments.",
    email:"manmeet.singh@utmail.utexas.edu",
    website:"https://manmeet3591.github.io/",
    linkedin:"https://www.linkedin.com/in/manmeet-singh-60bb6640/"
  },
  {
    name: "Naveen Sudharshan",
    image: process.env.PUBLIC_URL +"/images/naveen.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Parika Grover",
    image:process.env.PUBLIC_URL +"/images/Parika.jpg",
    title: "Staff",
    bio: "",
    email:"parika.grover@jsg.utexas.edu",
    linkedin:"https://www.linkedin.com/in/parika-grover-955627166",
    website:"https://parikagrover.wixsite.com/portfolio"
  },
  {
    name: "Rakshith Mallesh",
    image: process.env.PUBLIC_URL + "/images/rakshith.jpg",
    title: "Graduate Student",
    bio:"Rakshith is a first-year PhD student in the Department of Earth and Planetary Sciences at UT. He has a master's degree in physics and is currently studying the dynamics of formation and intensification of tropical cyclone.",
    email:"rakshith@utexas.edu",
    linkedin:"https://www.linkedin.com/in/rakshithm01/",
    website:"https://rakshith-mallesh.github.io/"
  },
  {
    name: "Sasanka Talukdar",
    image: process.env.PUBLIC_URL + '/images/Sasanka.png',
    title: "Graduate Student",
    bio: "Sasanka is a PhD student in the Department of Earth and Planetary Sciences at the Jackson School of Geosciences, University of Texas at Austin. His research focuses on understanding the physical processes that drive extreme weather events. Currently, he is investigating the influence of the brown ocean effect and convective cold pools on hurricane dynamics. His work integrates the use of dynamical models, such as the Hurricane Weather Research and Forecasting (HWRF) model, along with observational data to gain insights into these phenomena.",
    email:"st37357@my.utexas.edu",
    linkedin:"https://www.linkedin.com/in/sastalukdar/",
    website:"https://sastalukdar.github.io"
  },
  {
    name: "Shyama Mohanty",
    image: process.env.PUBLIC_URL + "/images/Shyama_1.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Ting-Yu Dai",
    image: process.env.PUBLIC_URL + '/images/Ting-Yu.png',
    title: "Graduate Student",
    bio: "I am a PhD student working on machine learning. My primary focus is ML for building energy modeling. I am also interested in applying computer vision models on remote sensing data such as precipitation or aerosol.",
    email:"td25238@my.utexas.edu",
    linkedin:"https://www.linkedin.com/in/ting-yu-dai-1abb1a1a1/",
    website:"https://www.funnyengineer.com/"
  },
  {
    name: "Trevor Brooks",
    image: process.env.PUBLIC_URL + '/images/Trevor.png',
    title: "Graduate Student",
    bio: "I am a PhD student in the Jackson School of Geosciences. I was named a Future Investigator in NASA Earth and Space Science and Technology (FINESST) in 2024. I work with prof. Dev Niyogi in the The Extreme Weather and Urban Sustainability (TExUS) lab. I am also co-advised by prof. Patrick Bixler at the LBJ School of Public Affairs. My interests are the intersection between policy and extreme weather with a focus on extreme heat and climate adapatation policies. ",
    email:"tb35259@my.utexas.edu",
    linkedin:"http://www.linkedin.com/in/trevorbrooks43"
  },   
  {
    name: "Xinxin Sui",
    image: process.env.PUBLIC_URL + '/images/Sui_2.png',
    title: "Graduate Student",
    bio: "Sui, Xinxin is a PhD candidate in the Environmental and Water Resources Engineering program at UT Austin. She has been named as a Future Investigator in NASA Earth and Space Science and Technology (FINESST) since 2022. She works with prof. Dev Niyogi and prof. Zong-Liang Yang in the extreme weather and urban sustainability (TExUS) lab. Her doctoral research investigates global urban rainfall anomalies using remote sensing datasets.",
    email:"xs3737@my.utexas.edu",
    linkedin:"https://www.linkedin.com/in/xinxin-sui-9b2673151/"
  },
  // Add more postdoc members here
];